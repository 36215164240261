/*Header CSS start*/
body {
    font-family: 'Poppins', sans-serif;
    overflow: scroll;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient( #b3b3b3, #777777) ; 
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient( #949494, #4b4b4b) ; 
  }

.header {
    background: url(../images/main-bg-50x50.jpg) repeat;
    padding: 0px;
}

.singapore-bg {
    text-align: start;
}

.menu-singapore {
    background-color: #E00025;
}

#pageBanner .owl-carousel{
    position: relative;
}
#pageBanner .owl-dots{
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);
}

/* Top Image slider in Home Css */

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    /* height: 775px; */
    object-fit: cover;
}

#singaporemenu {
    padding: 15px 0;
}

#singaporemenu ul li {
    padding: 0px;
    color: #f34662;
    border-right: 1px #f34662 solid;
}

#singaporemenu ul li:last-child {
    border-right: none;
}

#singaporemenu .nav-item a {
    color: #F5F5F5 !important;
    padding: 0 25px !important;
    text-align: center;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 300;
}

#singaporemenu .nav-item a.active {
    font-weight: 600;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.menu-singapore .navbar-toggler {
    border: 1px solid #fff;
}

.navbar-toggler .fa {
    color: #fff;
    font-size: 25px;
}

/*Header CSS ENDS*/
/*Footer CSS Start*/
.footer {
    background-image: url(../images/footer-bg.jpg);
    background-repeat: repeat;
    padding-top: 3rem;
}

.footer-content-para {
    font-size: 15px;
    color: #3b3b3b;
    margin-bottom: 2rem;
}

.footer-address {
    display: flex;
}

.footer-add-1 {
    width: 15%;
    cursor: pointer;
}

.footer-add-1 img {
    width: 35%;
}

.footer-add {
    color: #3b3b3b;
    font-size: 15px;
}


.footer-add a {
    color: #3b3b3b;
    text-decoration: none;
    font-weight: 600;
}

.footer-add span {
    color: #3b3b3b;
    text-decoration: none;
    font-weight: 600;
}

.footer-location p{
    cursor: pointer;
}

.footer-column {
    margin-bottom: 3rem;
}

.footer .useful-link ul {
    margin-bottom: 0px;
}

.footer .useful-link li {
    margin-bottom: 0.3rem;
}

.footer .linkdin li {
    text-align: center;
    width: 12%;
}

.footer .useful-link ul li a {
    color: #3b3b3b;
    font-size: 16px;
    text-decoration: none;
    line-height: 32px;
    text-transform: uppercase;
}

.useful-link h4 {
    margin-bottom: 1rem;
}

.privacy-p {
    margin-top: 2.5rem;
    margin-bottom: 7rem !important;
}

.copyright {
    background-color: #ebecee;
    color: #3b3b3b;
    font-size: 15px;
    padding: 15px;
}

/*Footer CSS End*/
/*Commen CSS Start*/
.section-padding {
    padding: 6.75rem 0;
}

.bg-lightgrey {
    background-color: #F1F1F1;
}

.bg-lightgreyy {
    background-color: #f5f5f5;
}

.bg-darkgrey {
    background-color: #cacaca;
}

.text-red {
    color: #E00025;
}

.text-black {
    color: #000;
}

.section-title {
    position: relative;
    margin-bottom: 5rem;
}

.section-title h1 {
    font-size: 50px;
}

.para {
    font-weight: 500;
    font-size: 18px;
    overflow: auto;
    padding-right: 10px;
}

.team-description-para .para {
    font-weight: 500;
    font-size: 18px;
    overflow: auto;
    height: 270px;
    padding-right: 10px;
}


.bg-lightgrey .btn-singapore a,
.bg-lightgreyy .btn-singapore a {
    cursor: pointer;
    background: url(../images/icons/next.png) no-repeat right top #fff;
}

.section-title:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    margin: 10px auto;
    width: 10%;
    height: 5px;
    background: #E00025;
}

.btn-singapore a {
    background: url(../images/icons/next.png) no-repeat right top #f7f7f7;
    padding: 15px 25px;
    color: #E00025 !important;
    max-width: 250px;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
}

/*Commen CSS End*/
/*About Us Page CSS start*/
.about-para p {
    /* text-align: center; */
    font-weight: 500;
    font-size: 18px;
}

.offshore-content {
    padding: 2rem 3rem;
    margin-bottom: 4rem;
}

.offshore-img img {
    width: 65%;
}

.offshore-content h4 {
    font-size: 25px;
}

/*About Us Page CSS end*/
/*Home page CSS Start*/
.home-about-us .btn-singapore {
    margin-top: 4rem;
}

.conscious-box {
    display: flex;
    flex-wrap: wrap;
    padding-left: 5rem;
}

.conscious-box-content {
    width: 45%;
    padding: 2rem 4rem;
}

.conscious-box-content.border-right {
    border-right: 3px solid #D8D8D8;
}

.conscious-box-img {
    text-align: center;
    width: 93%;
}

.conscious-box-content p {
    font-size: 20px;
        margin-top: 1rem;
}

.conscious-box-content h3{
    font-size: 35px;

}

.dot-bg {
    background-image: url(../images/home/bg-dots.png);
    background-repeat: no-repeat;
    background-position: top right;
}

.product-box {
    text-align: center;
    margin: 0 3rem;
/* cursor: pointer; */
}

.product-box .product-box-img {
    padding-bottom: 4rem;
    border-bottom: 3px solid #D8D8D8;
    margin-bottom: 3rem;
}

.product-box h3 {
    font-size: 26px;

}

.product-box .product-box-img img {
    width: 50%;
}

.download-box .list-inline-item {
    padding: 0 3 px;
    margin-right: 0px;
}

.download-box .list-inline-item.border-right {
    border-right: 2px solid #E00025;
}

.download-box {
    margin-bottom: 4rem;
}
.download-box{
    padding-right: 0px !important;
}

.ceo-desk-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ceo-desk-box .ceo-desk-box-img {
    width: 50%;
}

.ceo-desk-box-content .quotes {
    width: 10% !important;
}

.ceo-desk-box-img img {
    width: 80% !important;
    margin: 0 auto;
}

 /* .ceo-desk-box .ceo-mission {
    padding: 1rem 0;
    font-size: 20px;
    font-weight: 400;
    height: 300px;
    overflow-y: scroll;
    margin:1rem 0;
}  */
.ceo-desk-box .ceo-mission{
    position: relative;
    display: block;
    /* color: #838485;
    width: 24em; */
    height: calc(8.9em);
    overflow: hidden;
    white-space: normal;
}

.ceo-desk-box .ceo-mission .text-content {
  /* word-break: break-all; */
  position: relative;
  font-size: 20px;
  font-weight: 400;
  display: block;
  max-height: 3em;
}

.ceo-desk-box .ceo-mission .ellipsis {
  position: absolute;

  right: 0;
  top: calc(16.6em - 100%);
  text-align: left;
  background: white;
}
/* .ceo-desk-box .ceo-mission {
    
    font-size: 20px;
    font-weight: 400;margin-bottom: 1rem !important;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    
    overflow: hidden !important;

}  */
.ceo-desk-box-content .btn-singapore a{
    margin: 40px 0 0 0;
    /* margin-bottom: 1.3rem !important; */
}
.ceo-desk-box .ceo-position {
    font-size: 20px;
}

.ceo-desk-box .ceo-desk-box-content {
    padding: 3rem 0;
    width: 40%;
}

.modal-header{
    display: flex !important;
    align-items: start !important;
    align-content: start !important;
}
.modal-ui-hd {
    /* color: #E00025;  */
    padding: 20px 30px;
    /* border-bottom: 2px solid #000 !important; */
    position: relative;
}

.modal-ui-hd:after {
    content: "";
    position: absolute;
    left: 30px;
    right: 0;
    top: 90%;
    width: 20%;
    height: 4px;
    background: #c3c3c3;
}
.modal-ui-hd p{
    font-size: 1rem;
    font-weight: 500;
}
.modal-ui { 
    max-height: 50vh;
    overflow: auto;
    padding: 0px 50px;
    margin-bottom: 3rem;
}

.btn-close:focus{
box-shadow: none !important;
}

.modal-ui .ceo-position{
    font-size: 4px !important;
}

.home-modal .text-red {
    font-size: 1.75rem !important;
}

#ceo-desk .owl-dots {
    display: none !important;
}
#ceo-deck .text-red{
    margin-top: 1rem;
}

.react-multi{
    position:relative!important;
}

/* old  */
/* #ceo-desk .owl-prev {
    background: url(../images/icons/prev.png) no-repeat !important;
    width: 70px;
    height: 65px;
    left: 55%;
    bottom: -2rem;
    font-size: 0;
    position: absolute;
    border-radius: 0px;
    margin: 0;
    margin-right: -8px;
}
#ceo-desk .owl-prev {
    background: url(../images/icons/prev.png) no-repeat !important;
    width: 70px;
    height: 65px;
left: 59.5%;
    bottom: -2rem;
    font-size: 0;
    position: absolute;
    border-radius: 0px;
    margin: 0;
} */
/* old  */

/* #ceo-desk .owl-nav {
    position: relative !important;
    display: flex;
    justify-content: center;
} */

/* new */

.ceodesk .owl-prev {
    background: url(../images/icons/prev.png) no-repeat !important;
    cursor: pointer !important;
    width: 60px;
    height: 60px;
    /* left: 55%; */
    bottom: -2rem;
    /* font-size: 0;
    position: absolute;*/
    border-radius: 0px; 
    margin: 0;
    /* margin-right: -8px; */
    border: none;
}

.ceodesk .owl-next {
    background: url(../images/icons/next.png) no-repeat !important;
    width: 60px;
    height: 60px;
    /* left: 59.5%; */
    bottom: -2rem;
   /*  font-size: 0;
    position: absolute; */
    border-radius: 0px;
    margin: 0;
    border: none;
    cursor: pointer !important;
}
/* new */
 

/* display: block !important; will display navigation for CEO-desk even with 1 item */

.ceo-desk {
    background-image: url(../images/home/bg-ceo.png);
    background-position: bottom;
    background-size: cover;
}

.our-team-sec {
    background-image: url(../images/home/bg-our-team.png);
    background-size: cover;
}

.our-team-box {
    display: flex;
    justify-content: center;
}

.our-team-content {
    width: 50%;
    background-color: #fff;
    padding: 3rem 50px;
    height: auto;
}
.our-team-content .btn-singapore a{
    margin: 3rem 0;

}
.our-team-content .team-height{
    height: 120px;
}
.our-team-content .our-team-content-name {
    position: relative;
}

.our-team-content .our-team-content-name h4 {
    font-size: 30px;
}

.our-team-content .our-team-content-name .team-position {
    font-size: 19px;
    font-weight: 300;
}

.our-team-content .our-team-content-name .team-position:after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    margin: 10px auto;
    width: 20%;
    height: 5px;
    background: #e8e8e9;
}



.ourteam .owl-prev {
    background: url(../images/icons/prev.png) no-repeat !important;
    cursor: pointer !important;
  
    border: none;
    width: 60px;
    height: 60px;
    bottom: -3rem;
    border-radius: 0px;
    margin: 0;
    /* left: 50.5%; 

     font-size: 0;
    position: absolute;
    
     margin-right: -8px; */
}

.ourteam .owl-next {
    background: url(../images/icons/next.png) no-repeat !important;
    border: none;
    cursor: pointer !important;
    width: 60px;
    height: 60px;
    bottom: -3rem;
    /* left: 55%;
   
    font-size: 0;
    position: absolute; */
    border-radius: 0px;
    margin: 0;
}

.team-desc {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 109.2px;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*  display content in  6 lines */
/* 
.team-desc {
    padding: 2rem 0 0;
    font-size: 18px;
    font-weight: 500;
    display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  margin-bottom: 2rem;
  overflow: hidden !important;
} */
.team-desc {
    position: relative;
    display: block;
    line-height: 28px;
    /* color: #838485;
    width: 24em; */
    /* height: calc(9em + 5px); */
    height: 218px;
    overflow: hidden;
    white-space: normal;
}

.team-desc  .text-content {
  /* word-break: break-all; */
  position: relative;
  font-size: 20px;
  font-weight: 400;
  display: block;
  max-height: 3em;
}

.team-desc  .ellipsis {
  position: absolute;

  right: 0;
  top: calc(25em + 2px - 100%);
  text-align: left;
  background: white;
}




/*  display content in  scroll */
/* #our-team-slider .team-desc {
    margin: 2rem 0 ;
    font-size: 18px;
    font-weight: 500;
    height: 300px;
    overflow-y: scroll;
} */

.btn-singapore a{
    cursor: pointer;
    text-align: left;
}

#our-team-slider .btn-singapore a {
    margin: 0;

}

.our-team-image {
    width: 36%;
}

.owl-dots {
margin-top: 2rem;
    /* display: none; */
}
.carousel-control-prev,
.carousel-control-next{
    align-items: flex-end; /* Aligns it at the bottom */
    opacity: 1;
    position: absolute !important;
    bottom: -4.8rem;
}


.carousel-control-prev-icon{
    background: url(../images/icons/prev.png) no-repeat !important;
    width: 60px;
    height: 60px;
    position: absolute !important;
    left: 300%;
 
}
.carousel-control-next-icon{
    background: url(../images/icons/next.png) no-repeat !important;
    width: 60px;
    height: 60px;
    left: -236%;
    position: absolute !important;
}
/* .carousel-control-prev {
    background: url(../images/icons/prev.png) no-repeat !important;
    width: 70px;
    height: 65px;
     left: 50.5%; 
    bottom: -0.8rem;
    font-size: 0;
    position: absolute; 
    border-radius: 0px;
    margin: 0;
    margin-right: -8px;
}

.carousel-control-next {
    background: url(../images/icons/next.png) no-repeat !important;
    width: 70px;
    height: 65px;
   left: 55%;
    bottom: -0.8rem;
    font-size: 0;
    position: absolute; 
    border-radius: 0px;
    margin: 0;
    
} */

.carousel-button-group{
    position: relative !important;
    display: flex;
    justify-content: center;
}

/*Home Page CSS End*/
/*Products Page CSS Start*/
.product-sec {
    /* margin: 3rem 0; */
    margin: 1.5rem 0;
}

.products-content {
    margin: 2rem 1.5rem;
}

.products-content p {
    font-size: 18px;
}

.products-content h3 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.products-icons {
    border-right: 2px solid #D1D1D1;
}

.products-icons img {
    width: 65%;
}

/* .products-icons, .products-content{
    cursor: pointer;
} */

.breadcrumb h4,.breadcrumb h2,.breadcrumb h1, .breadcrumb h3,.breadcrumb h5, .breadcrumb h6, .breadcrumb p, .breadcrumb span{
    color: #929292 !important;
        text-decoration: none !important;
        padding: 0 20px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
            font-weight: 400 !important;
            font-size: 1rem !important;
            line-height: 1.5 !important;
            letter-spacing: 0.00938em !important;
            margin-bottom: 0rem;
}

/*Products Page CSS End*/
/*Contact Us CSS Start*/
.contact-us-sec {
    background-color: #F1F1F1;
}

.address-sec {
    display: flex;
    flex-wrap: wrap;
}

.address-sec .address-sec-icon {
    width: 10%;
    text-align: right;
    cursor: pointer;
}

.address-sec .address-sec-icon img {
    width: 40%;
    margin-right: 1rem;
}

.address-sec .address-sec-text {
    width: 80%;
}

.address-sec .address-sec-text h4 {
    font-size: 28px;

}

.address-sec .address-sec-text p {
    font-size: 18px;
}

.address-sec .address-sec-text p a {
    text-decoration: none;
    color: #000000;
}

.address-pointer h4,
.address-pointer p
{
    cursor: pointer;
}

.social-link-contact li {
    text-align: center;
    width: 8%;
}

.contact-us-form .address-sec-icon img {
    width: 70%;
}

.form-group .btn-submit {
    color: #fff;
    background-color: #E00025;
    border-radius: 0px;
    margin-top: 2rem;
    font-size: 20px;
    padding: 0.9rem 3rem;

}
.form-group .btn-submit-load {
    width: 170px;
    height: 60px;
    color: #fff;
    background-color: #c9c9c989;
    border-radius: 0px;
    margin-top: 2rem;
    font-size: 20px;
    padding: 0.9rem 3rem;
}

.contact-us-form {
    margin-left: 1.5rem;
}

.contact-us-form form {
    margin-top: 1rem;
}

.contact-us-form form .form-group {
    margin-bottom: 2rem;
}

.contact-us-form form .form-control {
    background-color: transparent;
    border: 0px;
    border-bottom: 2px solid #ced4da;
    border-radius: 0;
    height: 40px;
    font-size: 20px;
    box-shadow: none !important;
}

.contact-us-form form textarea.form-control {
    height: auto !important;
}

.contact-us-sec .border-right {
    border-right: 2px solid #ced4da;
}

.social-link-contact {
    margin-left: 15px;
}

/*Contact Us CSS End*/
/*News & insights css start*/
.outlook-box {
    padding: 1.4rem 1.4rem 2.5rem;
    margin: 10px!important;
    height: 100%;
    box-shadow: 4px 5px 4px #c9c9c9;
}

   .react-multi-carousel-list .outlook-box-content h3{
    -webkit-line-clamp: inherit; 
    height: auto;
    text-overflow: inherit;
    }

    .react-multi-carousel-list  .col-lg-12 {
        width: 100%;
            display: flex;
            height: 100%;
    }
     
    .react-multi-carousel-list .outlook-box {
        height: auto!important;
        position: relative;
    }
     
    .btn-singapore {
        padding-bottom: 25px;
    }
     
    .btn-singapore a {
        width: 100%;
        position: absolute;
        bottom: 0;
    }

.outlook-box-img img, .outlook-box-content h3,.outlook-box-content p{
    cursor: pointer;
}
.outlook-box-content h3{
    height: 75px;
}

.padding-2rem {
    padding: 0 2rem;
}

.outlook-box-content .btn-singapore a {
    font-size: 17px;
    text-align: left;
}
.outlook-box-img {
    height: 250px !important;
}
.outlook-box .outlook-box-img img {
    width: 100%;
    margin-bottom: 1.5rem;
    height: 230px;
}
.outlook-box-img-detail img{
    max-height: 500px;
    width: 100%;
    border: 4px solid #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.outlook-box-content h3 {
    font-size: 28px;
    margin-bottom: 1rem;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.outlook-box-content p {
    font-size: 18px;
    margin-bottom: 3rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

}

.outlook-box-content a {
    text-decoration: none;
    color: #000;
}

.outlook-box-content .btn-singapore a {
    margin: 0;
}

.outlook .owl-prev {
    background: url(../images/icons/prev.png) no-repeat !important;
    cursor: pointer !important;
    width: 60px;
    height: 60px;
    /* left: 55%; */
    bottom: -2rem;
    /* font-size: 0;
    position: absolute;*/
    border-radius: 0px;
    margin: 0;
    /* margin-right: -8px; */
    border: none;
}

.outlook .owl-next {
    background: url(../images/icons/next.png) no-repeat !important;
    width: 60px;
    height: 60px;
    /* left: 59.5%; */
    bottom: -2rem;
    /*  font-size: 0;
    position: absolute; */
    border-radius: 0px;
    margin: 0;
    border: none;
    cursor: pointer !important;
}

.half-section {
    position: relative;
    background-image: url(../images/news/half-circle.png);
    background-repeat: no-repeat;
    background-position: right;
}

.weekly-news-div {
    padding-right: 5rem;
}

.weekly-news-div .para {
    margin-bottom: 4rem;
}

.weekly-news-div .btn-singapore a {
    margin: 0;
}

.half-section-sec {
    width: 100%;
    height: 100%;
    top: 0;
    left: auto;
    right: 0;
}

.halfred-circle {
    margin-top: 2rem;
}

.download-sec .btn-singapore {
    margin: 0 auto;
}

.download-sec .btn-singapore a {
    max-width: 280px;
}
.downloads-sec .nav-item{
    cursor: pointer !important;
}

.download-buttons{
    font-size: 0.9rem;
    border-radius: 0 !important;
  
    border: none;
}

.download-buttons.download{
    background-color: #E00025 !important;
  
        
}
.download-buttons.view{
    background-color: #cacaca !important;
    color: #212529;
}

.download-buttons svg{
   width : 0.9rem;
   height: 0.9rem;
}
/*News & insights css end*/
/*Teams CSS Start*/
.team-description {
    padding: 2rem;
    height: 486px;
}

.team-description-name {
    position: relative;
    margin-bottom: 3rem;
}

.team-description-name h4 {
    font-size: 30px;
    font-weight: 600;
}

.team-description-name p {
    font-size: 18px;
}

.team-description-name:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    width: 20%;
    height: 4px;
    background: #c3c3c3;
}

.teams-mb {
    margin-bottom: 3rem;
}

/*Teams CSS End*/
/*Download CSS Start*/
.downloads-sec h2 {
    font-size: 28px;
    margin-bottom: 0px;
}

.downloads-sec .bg-darkgrey {
    padding: 1rem;
}

.downloads-sec .nav-tabs {
    flex-wrap: wrap;
    border: 0px;
    margin-bottom: 2rem;
}

.downloads-sec .nav-tabs li {
    width: calc((100% - 40px) / 4);
    background-color: #f5f5f5;
    margin: 1rem 0;
    margin-right: 10px;

}

.downloads-sec .nav-tabs li a {
    font-size: 22px;
    align-self: center;
    color: #000;
    height: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
}

.downloads-sec .nav-tabs li a.active {
    background-color: #E00025;
    border-radius: 0px;
    color: #fff;
}



.downloads-sec .nav-tabs li a:hover {
    background-color: #E00025;
    color: #fff;
    border-radius: 0px !important;
}

.view-download .nav-tabs li {
    width: calc((40% - 26px) / 2);
    text-align: center;
}

#qcm .view-download .nav-tabs li {
    width: calc((100% - 10px) / 2);
    text-align: center;
}

.view-download .nav-tabs li a {
    font-size: 22px;
    display: block;
}

.view-download .nav-tabs li img {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.quartertl-box {
    display: flex;
    flex-wrap: wrap
}

.quartertl-box-col {
    width: calc(100% / 2);
    margin-top: 3rem;
}

.quartertl-box-col h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 0px;
}

.view-download .quartertl-box .quartertl-box-col li:last-child {
    margin-right: 0px;
}

.documentResult {
    margin-top: 16px;
    text-align: center;
    display: block;
    background: #f7f7f7;
    padding: 54px 51px;
    /* display: none; */
}
.list{
    list-style-type: none;
}
.resultList li {
    position: relative;
    /* font-weight: 900; */
    color: #727272;
    padding-top: 15px;
    margin: 10px 0;
    min-height: 46px;
}
.listHeading{
    display: flex;
    justify-content: space-between;
}
.margin-left-15 {
    margin-left: 15px;
}

.margin-right-15 {
    margin-right: 15px;
}

.no-select {
    cursor: default;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/*Download CSS End*/


.breadcrumb span{
    
    color: #000000;
    padding: 0 20px;
}
.breadcrumb a{
    color: #929292;
    text-decoration: none;
    padding: 0 20px;
}
.breadcrumb a:hover{
    color: #3e3e3e;
    
}
.conscious-box-content h3 {
    font-size: 30px;
}
.conscious-box-content {
    padding: 1rem 1.5rem;
}
/* bouncing loader on contact us submit button start */
.bouncing-loader {
    display: flex;
    justify-content: center;
    margin-top: 3px;
}
  
.bouncing-loader > div {
    width: 3px;
    height: 10px;
    margin: 8px 3px;
    border-radius: 50%;
    padding: 2px 5px;
    background-color: #ffffff;
    opacity: 1;
    animation: bouncing-loader 1.0s infinite ;
}

@keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-16px);
    }
}
  
.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}
  
.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}

  .ql-align-left {
      text-align: start !important;
  }

  .ql-align-right {
      text-align: end !important;
  }

  .ql-align-center {
      text-align: center !important;
  }

  .ql-align-justify {
      text-align: justify !important;
  }
/* bouncing loader on contact us submit button end */
#our-team-slider .our-team-content{
    padding: 2rem 30px;
}

/* custom dots */
.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}
.custom-dot-list-style button {
border: none !important;
        width: 10px !important;
            height: 10px !important;
            margin: 5px 7px !important;
            background: #D6D6D6 !important;
            display: block !important;
            -webkit-backface-visibility: visible !important;
            transition: opacity 200ms ease !important;
            border-radius: 30px !important;
}
.custom-dot-list-style button:hover {
    background: #869791 !important;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
        background: #869791 !important;
}


.swal2-actions .swal2-confirm{
    color: #fff !important;
        background-color: #E00025!important;
        border-radius: 0px;
        margin-top: 2rem;
        font-size: 15px;
        padding: 0.75rem 2rem;
}
.swal2-confirm:focus,
.swal2-cancel:focus {
    box-shadow: none !important;
    border: none !important;
}   

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;}
input[type=number] {
            -moz-appearance: textfield;
        }
.txt-rgt{
    text-align: right !important;
}
.access-btn{
    background: #212529 !important;
    color: #fff;
    border-radius: 0;
    border: 0px solid #000;
    width: 150px;
    font-weight: 600;
    padding: 8px;
}
.access-btn:hover{
    background: #E00025 !important;
    color: #ffffff;
    border: 0px solid #000;

}


.modal-downloads { 
    max-height: 80vh;
    /* padding: 30px 50px; */
    margin: 3rem;
    overflow: auto;
    padding: 0 !important;

}
.modal-downloads .table td{
    white-space: nowrap;
}
.btn-view{
    background: #696969;
    color: #ffffff;
    padding: 10px 30px;
    border: 0;
    margin-right: 15px;
}
.btn-view:hover{
    background: #212529;
    color: #ffffff;
   
}
.btn-dwnld{
    background: #c3c3c3;
    color: #000000;
    padding: 10px 30px;
    border: 0;
}
.btn-dwnld:hover{
    background: #212529;
    color: #ffffff;
}

/* .table-over{
    overflow: auto;

} */
table {
    border: 0px solid;
    border-collapse: collapse ;
    margin-bottom: 0!important;
  }
  thead{
    position: sticky;
    position: -webkit-sticky;

    z-index: 100;
    top: 0; /* Don't forget this, required for the stickiness */
   
}
  table thead{
    position: sticky;
    position: -webkit-sticky;

    inset-block-start: 0;
  }
  table th{
    color: #fff;
    background-color: #E00025 !important;
    text-align: center !important;
    padding: 14px 8px !important;
    position: sticky;
    z-index: 99;
    top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
  td {
    font-size: 14px !important;
    padding: 15px !important;
    border: 1px solid #e5e5e5;
    vertical-align: top;
  }
  table thead {
    inset-block-start: 0; /* "top" */
  }
  table tfoot {
    inset-block-end: 0; /* "bottom" */
  }
  table tr {
    border-bottom: 1px solid #f2f2f2;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
   tr:hover {
    background-color: #ffdbdb;
    transition: all 0.2s;
  }
@media screen and (max-width: 1024px) {
	.ceo-position {
		margin-bottom: 60px;
	}
	.btn-singapore a {
		bottom: -15px;
	}
}