@media screen and (min-width: 320px) and (max-width: 768px) {

    /*Header CSS Start*/
    #singaporemenu ul li {
        border-right: 0px;
        border-bottom: 1px #f34662 solid;
        margin-bottom: 10px;
    }

    #singaporemenu ul li:last-child {
        border-bottom: 0px;
    }
    .our-team-content .team-height{
        height: 200px;
    }
    .our-team-content .our-team-content-name h4 {
        font-size: 27px;
    }
    .our-team-content .our-team-content-name .team-position:after{
        left:40%;
    }
    .our-team-content .our-team-content-name .team-position{
        font-size: 17px;
    }
    .team-description-para .para {
        height: 225px;
    }
   
    .ceo-desk-box-content .btn-singapore a{
        margin: 40px auto;
    }
    /*Header CSS End*/
    .footer-column.useful-link {
        margin-bottom: 0px;
    }

    .privacy-p {
        margin-top: 0px !important;
        margin-bottom: 2rem !important;
    }

    .footer-logo {
        margin-bottom: 3rem;
        width: 85%;
    }

    .footer-add a {
        font-size: 14px;
    }

    .footer .useful-link ul {
        display: flex;
        flex-wrap: wrap;
    }

    .footer .useful-link li {
        width: 50%;
    }

    /*Commen CSS Start*/
    .section-title h1 {
        font-size: 40px;
    }

    .section-title:after {
        width: 25%;
    }

    .section-padding {
        padding: 3.75rem 0;
    }

    /*Commen CSS end*/
    /*Home CSS Start*/
    .block-img {
        width: 70%;
    }

    .conscious-box {
        padding-left: 0px;
        margin-bottom: 3rem;
    }

    .conscious-box-content {
        width: 50%;
        padding: 1rem;
    }

    .conscious-box-content h3 {
        font-size: 25px !important;
    }

    .conscious-box-content p {
        font-size: 15px;
    }

    .conscious-box-img {
        margin-left: 5%;
    }

    .product-box .product-box-img img {
        width: 60%;
    }

    .product-box .product-box-img {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
    }

    .product-box {
        text-align: center;
        padding: 0;
        margin-bottom: 3rem;
        cursor: pointer !important;
    }

    .product-box h3 {
        font-size: 20px;
    }

    .home-products {
        padding-bottom: 3rem;
    }

    .ceo-desk-box {
        flex-wrap: wrap;
    }

    .ceo-desk-box .ceo-desk-box-img,
    .ceo-desk-box .ceo-desk-box-content {
        width: 100%;
        text-align: center;
    }

    .ceo-desk-box .ceo-desk-box-content {
        text-align: center;
    }

    .ceo-desk-box-content .quotes {
        margin: 0 auto;
    }

    /* #ceo-desk .owl-next {
        left: 48.5%;
        bottom: -1rem;
    }

    #ceo-desk .owl-prev {
        left: 30%;
        bottom: -1rem;
    } */

    #our-team-slider .our-team-box {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    #our-team-slider .our-team-content,
    #our-team-slider .our-team-image {
        width: 100%;
    }

    .owl-carousel .owl-item img {
        /* width: 70%; */
        margin: 0 auto;
    }
    .our-team-image,
    .our-team-content {
        text-align: center;
        width: 100% !important
    }

    #our-team-slider .our-team-content .our-team-content-name:after {
        width: 50%;
        right: 0;
    }

    #our-team-slider .btn-singapore a {
        text-align: left;
    }
        #ceo-desk .btn-singapore a {
        text-align: left;
    }

    #our-team-slider .owl-prev {
        left: 30.5%;
        bottom: -3.8rem;
    }

    #our-team-slider .owl-next {
        left: 49.5%;
        bottom: -3.8rem;
    }

    #our-team-slider .our-team-content {
        padding: 2rem 20px;
    }

    #ceo-desk .owl-nav,
    #our-team-slider .owl-nav {
        width: 99%;
        margin: 0 auto;
    }
    /* #ceo-desk .owl-prev{
        left: 39%;
    } */
    #our-team-slider .btn-singapore a {
        margin: 0 auto;
    }

    

    /*Home CSS End*/
    /*About Us Page CSS start*/
    .offshore-img img {
        width: 40%;
        margin-bottom: 2rem;
    }

    .offshore-content h4 {
        font-size: 22px;
    }

    .offshore-content {
        padding: 1rem 2rem;
    }

    /*About Us Page CSS end*/
    /*Products Page CSS Start*/
    .products-icons img {
        width: 35%;
    }

    .products-icons {
        border-right: 0;
        border-bottom: 2px solid #D1D1D1;
        padding-bottom: 2rem;
        text-align: center;
    }

    .products-content {
        text-align: center;
    }

    .products-content {
        margin: 2rem 0rem;
    }

    .products-content p {
        font-size: 16px;
    }

    .products-content h3 {
        font-size: 28px;
    }

    /*Products Pages CSS End*/
    /*Contact Us CSS Start*/
    .address-sec .address-sec-text h4 {
        font-size: 25px;
    }

    .address-sec .address-sec-icon img {
        width: 50%;
    }

    .social-link-contact li {
        text-align: center;
    }

    .address-sec.mb-4,
    .address-sec.mb-5 {
        margin-bottom: 2rem !important;
    }

    .social-link-contact {
        margin-bottom: 3rem;
    }

    .contact-us-sec .border-right {
        border-right: 0px solid #ced4da;
    }

    .contact-us-form {
        margin-left: 0rem;
    }

    .social-link-contact {
        margin-left: 1%;
    }

    /*Contact Us CSS End*/
    /*News & insights css start*/
    .news .section-title h1 {
        font-size: 30px;
    }

    .news .para {
        font-weight: 500;
        font-size: 16px;
    }

    .news .section-padding {
        padding: 3.75rem 0;
    }

    .outlook-box {
        padding: 1rem;
        margin-bottom: 2rem;
        height: auto;
    }

    .outlook-box-content h3 {
        font-size: 25px;
    }

    .weekly-news-div {
        padding-right: 0px;
    }

    .weekly-news-div .para {
        text-align: center;
    }

    .news .bg-lightgrey .btn-singapore,
    .news .bg-lightgreyy .btn-singapore {
        margin-bottom: 2rem;
    }

    .half-circle-div-mobile {
        background-image: url(../images/news/half-circle.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        margin-top: 2rem;
    }

    .half-section {
        background-image: none;
    }

    .news .half-circle-div {
        text-align: right !important;
    }

    /*News & insights css end*/
    /*Teams CSS Start*/
    .team .section-padding {
        padding: 3rem 0;
    }

    .team-img {
        text-align: center;
    }

    /*Teams CSS end*/
    /*Download CSS Start*/
    .view-download .nav-tabs li {
        width: 40%;
        text-align: center;
    }

    .view-download .nav-tabs li a {
        font-size: 16px;
        padding: 1rem;
    }

    .downloads-sec .nav-tabs li {
        width: calc((100% - 10px) / 2);
        margin: 5px;
    }

    .downloads-sec .nav-tabs li:nth-child(even) {
        margin-right: 0px;
    }

    .downloads-sec .nav-tabs li:nth-child(odd) {
        margin-left: 0px;
    }

    .downloads-sec .nav-tabs li a {
        font-size: 16px;
        padding: 1rem;
    }

    .downloads-sec h2 {
        font-size: 22px;
    }

    .quartertl-box-col {
        width: 100%;
        margin-top: 1rem;
    }

    .downloads-sec #qcm .view-download .nav-tabs {
        margin-bottom: 0px;
    }

    .margin-left-15 {
        margin-left: 0px;
    }

    .margin-right-15 {
        margin-right: 0px;
    }

    /*Download CSS End*/

.carousel-control-prev-icon{
    
    left: 244%;
 
}
.carousel-control-next-icon{
  
    left: -223%;
   
}
.our-team-box{
    display: flex;
    flex-direction: column-reverse;
}

}

@media screen and (min-width: 576px) and (max-width: 768px) {

    /*Home Page CSS Start*/
    .conscious-box-content h3 {
        font-size: 30px !important;
    }
    .quotes{
        width: 40%;
    }
    .ceo-desk-box-content .btn-singapore a{
        margin: 30px auto;
    }
    #our-team-slider .owl-prev{
        left: 34.5%;
    }
    #our-team-slider .owl-next{
        left: 49.5%;
    }   
     .conscious-box-content p {
        font-size: 18px;
    }

    .product-box {
        padding: 0 2rem;
    }

    .product-box h3 {
        font-size: 26px;
    }

    .product-box .product-box-img img {
        width: 45%;
    }

    #ceo-desk .owl-nav,
    #our-team-slider .owl-nav {
        width: 60%;
        margin: 0 auto;
    }
    .carousel-control-prev, .carousel-control-next{
        bottom: -2.5rem;
    }
    .our-team-content .our-team-content-name .team-position:after {
        left: 40%;
    }

    /*About Us Page CSS start*/
    .offshore-img img {
        width: 100%;
        margin-bottom: 0rem;
    }

    /*About Us Page CSS end*/
    /*News & insights css start*/
    .half-circle-div-mobile {
        background-image: none;
    }

    .half-section {
        background-image: url(../images/news/half-circle.png);
        background-position: right bottom;
    }

    .news .half-circle-div {
        text-align: center !important;
    }

    /*News & insights css end*/
    /*Download CSS Start*/
    .downloads-sec .nav-tabs li a {
        font-size: 22px;
    }

    #qcm .view-download .nav-tabs li {
        width: calc((100% - 15px) / 2);
    }

    /*Download CSS End*/
        #our-team-slider .team-desc {
            padding: 2rem 0 0;
            font-size: 18px;
            font-weight: 500;
            width:100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            background:#fff;
              margin-bottom: 2rem;
        }
        .carousel-control-prev-icon{
    
            left: 287%;
         
        }
        .carousel-control-next-icon{
          
            left: -223%;
           
        }
}

@media screen and (min-width: 769px) and (max-width: 991px) {

    /*Home CSS Start*/
    .conscious-box {
        padding-left: 1rem;
    }

    .conscious-box-content {
        width: 50%;
    }

    .conscious-box-content {
        width: 50%;
        padding: 2rem 2rem;
    }

    .conscious-box-content h3 {
        font-size: 30px;
    }

    .conscious-box-content h3 {
        font-size: 30px;
    }

    .conscious-box-content p {
        font-size: 18px;
    }

    .conscious-box-img img {
        margin-left: 2%;
    }

    .product-box {
        padding: 0 1rem;
    }

    .product-box .product-box-img {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
    }

    .product-box h3 {
        font-size: 20px;
    }

    .ceo-desk-box .ceo-desk-box-content {
        width: 50%;
    }

    /* #ceo-desk .owl-next {
        bottom: -1rem;
        left: 57.5%;
    } 

    #ceo-desk .owl-prev {
        left: 49%;
        bottom: -1rem;

    }*/

    #our-team-slider .our-team-image {
        width: 50%;
    }

    #our-team-slider .our-team-content {
        padding: 2rem 15px;
    }

    #our-team-slider .owl-next {
        left: 59%;
    }

    /*Home CSS End*/
    /*About Us Page CSS start*/
    .offshore-img img {
        width: 100%;
    }

    /*About Us Page CSS end*/
    /*News & insights css start*/
    .weekly-news-div {
        padding-right: 0rem;
    }

    .half-section {
        background-position: 32rem 90%;
    }

    .section-title h1 {
        font-size: 35px;
    }

    .outlook-box {
        padding: 0.5rem;
    }

    .outlook-box-content h3 {
        font-size: 18px;
    }

    .outlook-box-content p {
        font-size: 14px;
    }

    /*News & insights css end*/
    /*Download CSS Start*/
    .downloads-sec .nav-tabs li a {
        font-size: 20px;
        padding: 0.6rem;
    }

    .downloads-sec .nav-tabs li {
        width: 22%;
    }

    /*Download CSS End*/

         #our-team-slider .team-desc {
             padding: 2rem 0 0;
            font-size: 14px;
             font-weight: 500;
             display: -webkit-box;
             -webkit-line-clamp: 3;
             -webkit-box-orient: vertical;
             margin-bottom: 2rem;
             overflow: hidden !important;
         }
         .carousel-control-prev-icon{
    
            left: 286%;
         
        }
        .carousel-control-next-icon{
          
            left: -223%;
           
        }
}

@media screen and (min-width: 992px) and (max-width:1100px) {
    .our-team-image {
        width: 50%;
    }
    .our-team-content .our-team-content-name .team-position{
        font-size: 18px;
    }
    .team-desc{
        padding: 1.5rem 0 0;
       font-size: 18px;
        -webkit-line-clamp: 3;
       
        margin-bottom: 2rem;
      
    
    }
    .btn-singapore a{
        margin: 0;
    }
    
    .carousel-control-prev-icon{
    
        left: 300%;
     
    }
    .carousel-control-next-icon{
      
        left: -223%;
       
    }

    /*Commen CSS Start*/
    #singaporemenu .nav-item a {
        padding: 0 18px !important;
        font-size: 15px;
    }

    /*Commen CSS End*/

        #our-team-slider .team-desc {
            padding: 2rem 0 0;
            font-size: 18px;
            font-weight: 500;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-bottom: 2rem;
            overflow: hidden !important;
        }
}

/*================= Media Query ends here =================*/

